<template>
  <div>
    <template v-if="showFuncionalidade">
      <div class="grid-content">
        <div class="pesquisa-content full-width">
          <div id="func-pesquisa" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="grid-content bg-pesquisa">
        <div class="pesquisa-content margin-top-20">
          <div class="header-pesquisa box">
            <img :src="clientLogo"/>
            <span>
              {{ pesquisaTemplate ? pesquisaTemplate.titulo : '' }}
            </span>
          </div>
          <div id="func-pesquisa" class="padding-30 box"/>
          <div class="footer-pesquisa">
            <el-row class="msg-install">
              <span>Instale nosso aplicativo</span>
            </el-row>
            <el-row class="img-install">
                <div class="cursor-pointer" @click="goToAppStore">
                  <img class="img-app" :src="require('@/assets/img/img_download_ios.png')">
                </div>
                <div class="cursor-pointer" @click="goToGooglePlay">
                  <img class="img-app" :src="require('@/assets/img/img_download_android.png')">
                </div>
            </el-row>
            <el-row class="info">
                <div>
                  <strong>{{ nomeOperadora }}</strong> | {{ nomeAplicacao}}| <a :href="siteOperadoraUrl"> {{ siteOperadora }}</a>
                </div>
            </el-row>
            <el-row class="ans">
              <div><span>ANS - Nº {{ numeroAns }}</span></div>
            </el-row>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { encodeEmbedData, urlVerify } from '@/utils/utils'
import { ConfiguradorResource } from '@/API'
import canal from '@/utils/canal'
import { mobileSaudeLogo } from '@/utils/ImageBucket'

export default {
  name: 'Pesquisa',
  props: {
    funcionalidade: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      titulo: '',
      pesquisaJson: {},
      pesquisaTemplate: null,
      statusCode: null
    }
  },
  computed: {
    ...mapGetters('Beneficiario', ['user']),
    ...mapState('Pesquisa', ['token']),
    ...mapGetters('SetupApp', ['bundle', 'bundleWeb', 'aplicacao', 'imagens', 'instanciaAplicacao']),
    msLogo () {
      return mobileSaudeLogo()
    },
    favicon () {
      let favicon = this.imagens.favIconWeb
      return (typeof favicon === 'undefined' || favicon === '') ? 'favicon.ico' : favicon
    },
    queryExpirado () {
      return !!this.$route.query.expirado
    },
    clientLogo () {
      return this.imagens.logoLoginWeb
    },
    numeroAns () {
      return this.aplicacao.numeroAns
    },
    nomeAplicacao () {
      return this.aplicacao.appName
    },
    nomeOperadora () {
      return this.aplicacao.operadoraNome
    },
    siteOperadoraUrl () {
      if (!this.aplicacao.operadoraUrl) {
        return ''
      }
      return urlVerify(this.aplicacao.operadoraUrl)
    },
    siteOperadora () {
      return this.siteOperadoraUrl.replace('http://', '').replace('https://', '')
    },
    showFuncionalidade () {
      return this.funcionalidade
    }
  },
  metaInfo () {
    return {
      title: `${this.pesquisaTemplate ? this.pesquisaTemplate.titulo : ''}`,
      link: [
        { rel: 'favicon', href: this.favicon },
        { rel: 'icon', href: this.favicon }
      ]
    }
  },
  methods: {
    ...mapMutations('SetupApp', ['SET_ESQUEMA_DE_COR']),
    async init () {
      await this.aplicaEsquemaCor(undefined, this.bundle, this.bundleWeb)

      this.pesquisaJson = this.decodeJson64()
      await this.getConfig()
    },
    async getConfig () {
      const template = await ConfiguradorResource.getTemplatePesquisa({
        bundle: this.bundle,
        pesquisaEnvioId: this.pesquisaJson.pesquisaEnvioId
      })

      if (template.statusCode !== 200) {
        this.pesquisaTemplate = template.data
        this.statusCode = template.statusCode
      } else {
        this.pesquisaTemplate = template
      }

      setTimeout(() => {
        this.initFunc()
      }, 500)
    },
    initFunc () {
      const urlBase = process.env.NODE_ENV !== 'development' ? window.location.origin : ''
      const urlFuncionalidades = process.env.VUE_APP_FUNCIONALIDADES_FRONT

      let url = urlBase + urlFuncionalidades

      if (urlFuncionalidades.startsWith('http')) {
        url = urlFuncionalidades
      }

      let funcionalidade = window.omnichannelSDK.embed({
        id: 'func-pesquisa',
        url,
        json: {
          idFuncionalidade: 56,
          bundle: encodeEmbedData(this.bundle),
          plataforma: true,
          height: parseInt(`600`),
          canal: canal,
          instanciaAplicacao: this.instanciaAplicacao,
          pesquisa: this.pesquisaJson,
          showHeader: this.showFuncionalidade,
          pesquisaTemplate: this.pesquisaTemplate,
          statusCode: this.statusCode
        }
      })
      if (!funcionalidade) {
        return
      }

      funcionalidade.on('CREATED', (response) => {
      })

      funcionalidade.on('LOADING', (response) => {
      })

      funcionalidade.on('TROCA_PAGINA', (response) => {
        this.titulo = response.titulo
      })
    },
    async aplicaEsquemaCor (esquemaCor, bundle, bundleWeb) {
      try {
        const res = await ConfiguradorResource.getEsquemaCor({
          bundle,
          bundleWeb,
          esquemaCor
        })
        this.SET_ESQUEMA_DE_COR(res)
      } catch (ignored) {
      }
    },
    decodeJson64 () {
      const json64 = this.$route.params.json64 || this.$route.query.idRegistro

      if (!json64) {
        return {}
      }

      try {
        const jsonDecoded = window.atob(json64)
        return JSON.parse(jsonDecoded)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        return {}
      }
    },
    goToAppStore () {
      window.open(`${this.aplicacao.textos.urlAppStore}`)
    },
    goToGooglePlay () {
      window.open(`${this.aplicacao.textos.urlGooglePlay}`)
    }
  }
}
</script>

<style scoped lang="less">
.area-beneficiario {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.bg-img {
  background: #EEEEEE;
}

.bg-pesquisa {
  background: #DDDDDD 0 0 no-repeat padding-box;
  background: url("/img/bg_pesquisa.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.grid-content {
  min-height: 100%;
}

.box-area {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.box-area-beneficiario {
  max-width: 435px;
  height: 316px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 20px #0000000D;
  border-radius: 20px;
  opacity: 0.9;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.box-area-mensagem {
  text-align: center;
  font: normal normal 300 32px/43px Open Sans;
  color: #000000;
}

.rodape-beneficiario {
  background: #FFFFFF 0 0 no-repeat padding-box;
  opacity: 0.5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.rodape-dev-por {
  text-align: right;
  font: normal normal normal 11px/13px Open Sans;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
  margin-right: 5px;
}

.padding-30 {
  padding: 30px;
}
.margin-top-20 {
  margin-top: 20px;
}
.pesquisa-content {
  width: 600px;
  margin-bottom: 20px;
  .box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #0000000D;
    border-radius: 10px;
  }
  .header-pesquisa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-right: 20px;
    img {
      width: 250px;
      max-width: 250px;
    }
    span {
      font: normal normal 600 20px/27px Open Sans;
      letter-spacing: 0;
      color: #333333;
    }
  }
  .footer-pesquisa {
    margin-top: 30px;
    .msg-install {
      text-align: center;
      font: normal normal 600 16px/22px Open Sans;
      color: #333333;
    }
    .img-install {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      .img-app {
        padding: 0 10px;
      }
      .cursor-pointer {
        cursor: pointer;
      }
    }
    .info {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font: normal normal normal 12px/17px Open Sans;
      letter-spacing: 0;
      color: #333333;
      a {
        text-align: center;
        text-decoration: underline;
        color: #333333;
      }
    }
    .ans {
      margin-top: 13px;
      text-align: center;
      font: normal normal bold 12px/14px Roboto;
      letter-spacing: 0.23px;
      color: #FEFEFE;
      > div {
        background: #000000 0% 0% no-repeat padding-box;
        padding: 6px 2px;
        display: inline-block;
        span {
          border: #FFF 1px solid;
          padding: 3px 12px
        }
      }
    }
  }
}
.full-width {
  width: 100%;
}

@media (max-width: 767px) {
  .pesquisa-content {
    width: 95%;
  }
}
</style>
